import Image from 'next/image';
import styled from 'styled-components';

// eslint-disable-next-line import/extensions
import Bg from 'UI/assets/images/raredeals/bg.jpg';

/**
 * LoginAside.
 *
 * @returns The component.
 */
const LoginAside = () => (
    <Aside>
        <StyledImage
            alt="Login"
            placeholder="blur"
            src={Bg}
            fill
            priority
        />
    </Aside>
);

LoginAside.displayName = 'LoginAside';
LoginAside.defaultProps = {testId: undefined};

export {LoginAside};

const Aside = styled.aside`
    flex: 0 0 50%;
    max-width: 1920px;
    position: relative;
`;

const StyledImage = styled(Image)`
    object-fit: cover;
    object-position: center;
`;