import {useEffect, useState} from 'react';

import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

import {useFormFieldValue} from 'UI/hooks/useFormFieldValue';
import {useThemeColors} from 'UI/hooks/useThemeColors';

/**
 * The `useRequestLogin` hook manages the state and behavior for a login form specifically tailored for user authentication in the 'raredeals' application.
 * It leverages the `useFormFieldValue` hook to listen to and validate the presence of both 'email' and 'accessCode' fields, enabling or disabling the submit button accordingly.
 * This dynamic validation ensures users are only able to submit the form when all required fields are populated, enhancing form usability and preventing erroneous submissions.
 * Additionally, the hook utilizes translation and theming hooks to provide localized text and theme-based styling properties, ensuring the login form is both accessible and visually consistent with the overall application design.
 *
 * @returns An object containing the color theme, a flag indicating whether the submit button should be disabled, and localized text resources, enriching the form's functionality and presentation.
 *
 * @example
 * ```tsx
 * const { colors, submitIsDisabled, t } = useRequestLogin();
 * // Use these values to construct a responsive, interactive login form.
 * ```
 */
export const useRequestLogin = () => {
    const {t} = useTranslation('raredeals', {keyPrefix: 'login'});
    const colors = useThemeColors();
    const router = useRouter();
    const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    const {accessCode, email} = router.query;
    const formEmail = useFormFieldValue('email');
    const formAccessCode = useFormFieldValue('accessCode')!;

    useEffect(() => {
        if (formEmail && formAccessCode) {
            setSubmitIsDisabled(false);
        } else {
            setSubmitIsDisabled(true);
        }
    }, [formAccessCode, formEmail]);

    return {
        accessCode,
        colors,
        email,
        submitIsDisabled,
        t
    };
};