import type {ChangeEvent} from 'react';

import {media, Spacer, spacing} from '@nfq/react-grid';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {useFormField} from 'UI/components/action/inputs/hooks/useFormField';
import {P} from 'UI/components/layout/raredeals/Text';

interface ComponentProps {
    id?: string;
    /**
     * The value with which the input field is initialized.
     */
    initialValue: string;
    /**
     * The label for the input field.
     */
    label?: string;
    /**
     * The name attribute for the input field.
     */
    name: string;
    /**
     * The callback function to handle value changes.
     *
     * @param value The new value of the input field.
     */
    onChange?(value: string): void;
    /**
     * The placeholder text for the input field.
     */
    placeholder: string;
    /**
     * The test ID for the input field.
     */
    testId?: string;
    /**
     * The type of the input field.
     */
    type: 'email' | 'password' | 'text';
}

/**
 * Represents an input field component with a label, name, value change handler, style, and test ID.
 * The component renders an input field with the specified label, style, and test ID.
 * It also provides a callback function to handle value changes.
 *
 * @param props          The component properties.
 * @param props.label    The label for the input field.
 * @param props.name     The name attribute for the input field.
 * @param props.onChange The callback function to handle value changes. It takes a single parameter of type string.
 * @param props.testId   The test ID for the input field.
 * @returns The rendered InputField component.
 *
 * @example
 * ```
 * const handleChange = (value: string) => {
 *   // Handle value change
 * };
 *
 * <InputField
 *   label="title"
 *   name="title"
 *   onChange={handleChange}
 *   testId="title-field"
 *   placeholder="Enter title"
 * />
 * ```
 */
const InputField = observer(({
    id = undefined,
    initialValue = '',
    label = '',
    name,
    onChange = () => {},
    placeholder,
    testId = 'InputField',
    type
}: ComponentProps) => {
    const {handleFieldChange, value} = useFormField(name, initialValue);

    /**
     * Event handler for handling change events on an input element.
     *
     * @param event The change event.
     */
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleFieldChange(event.target.value);
        onChange(event.target.value);
    };

    return (
        <Label $size="sm">
            {label}
            <Spacer y={2} />
            <Input
                data-cy={`${testId}-input`}
                id={id}
                name={name}
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={handleChange}
            />
        </Label>
    );
});

InputField.displayName = 'InputField';

export {InputField};

export const Label = styled(P).attrs({as: 'label'})`
    width: 100%;
`;

export const Input = styled.input`
    border: 1px solid ${({theme}) => theme.colors.raredealsInputBorderColor};
    border-radius: 12px;
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: 1.4rem;
    height: 100%;
    line-height: 1.3;
    padding: ${spacing(4)};
    width: 100%;

    ${media('md')} {
        font-size: 1.6rem;
    }
`;