import {Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Button} from 'UI/components/action/raredeals/button';
import {InputField} from 'UI/components/action/raredeals/InputField';
import {Color, P} from 'UI/components/layout/raredeals/Text';

import {DropdownTransition} from 'UI/animations/raredeals';
import {useRequestLogin} from 'UI/modules/raredeals/login/RequestLogin/useRequestLogin';

import {ArrowRight} from 'Images/icons/raredeals';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    handleSwitch(showLogin: boolean): void;
    hasLoginError: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
}

/**
 * The `RequestLogin` component provides a form specifically designed for users to enter an access code and log into a restricted area.
 * This form is particularly useful in scenarios where access to certain premium or restricted content is managed through a code-based authentication system.
 * Users can opt to stay logged in by selecting a checkbox, enhancing user experience by reducing the need for frequent logins. This component also includes a button to switch
 * back to a request code view if the user needs to obtain an access code, showcasing its adaptability to different user states. The form submission and state management are handled
 * through the `useRequestCode` hook, which, despite its name, is reused here for handling the login process.
 *
 * @param props               The component props.
 * @param props.handleSwitch  A function that toggles the view between login and code request forms, allowing for a flexible user interface interaction.
 * @param props.testId        A string that provides a unique identifier for the component, enhancing the testing and maintenance processes by making the component easily identifiable in tests.
 * @param props.hasLoginError A boolean value indicating whether there was an error during the login process, such as an incorrect access code or email.
 * @returns A React component that renders a login form for entering an access code with additional options to remain logged in or switch back to requesting a new code.
 *
 * @example
 * ```tsx
 * // How to render the RequestLogin component within a user interface.
 * <RequestLogin testId="loginComponent" handleSwitch={toggleFormView} />
 * ```
 */
const RequestLogin = observer(({handleSwitch, hasLoginError, testId = 'RequestLogin'}: ComponentProps) => {
    const {
        accessCode,
        colors,
        email,
        submitIsDisabled,
        t
    } = useRequestLogin();

    return (
        <Wrapper data-cy={testId}>
            <InputWrapper>
                <InputField
                    id="email"
                    initialValue={email?.toString() ?? ''}
                    label={t('email')}
                    name="email"
                    placeholder={t('email')}
                    type="email"
                />
                <Spacer y={6} />
                <InputField
                    id="password"
                    initialValue={accessCode?.toString() ?? ''}
                    label={t('accessCode')}
                    name="accessCode"
                    placeholder={t('accessCode')}
                    type="text"
                />
            </InputWrapper>
            {hasLoginError && (
                <StyledResponse
                    key="error"
                    animate="animate"
                    exit="exit"
                    initial="exit"
                    variants={DropdownTransition}
                >
                    <P $size="xs">
                        <Color $color={colors.raredealsErrorColor}>
                            {t('requestCodeError')}
                        </Color>
                    </P>
                </StyledResponse>
            )}
            <Spacer y={4} />
            <DetailWrapper>
                <Button
                    as="button"
                    size="small"
                    type="button"
                    variant="ghost"
                    onClick={() => handleSwitch(false)}
                >
                    <P $size="sm" $weight="semibold">{t('requestCode')}</P>
                </Button>
            </DetailWrapper>
            <Spacer y={8} />
            <Button
                as="button"
                isDisabled={submitIsDisabled}
                type="submit"
                variant="primary"
                width="full"
            >
                <StyledP $size="sm">
                    {t('login')}
                    <Spacer x={1} />
                    <ArrowRight />
                </StyledP>
            </Button>
        </Wrapper>
    );
});

RequestLogin.displayName = 'RequestLogin';

export {RequestLogin};

const Wrapper = styled.div``;

const DetailWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
`;

const StyledResponse = styled(motion.div)`
    position: absolute;
`;

const StyledP = styled(P)`
    align-items: center;
    display: flex;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;