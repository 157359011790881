import {Container, Hidden} from '@nfq/react-grid';
import {AnimatePresence} from 'framer-motion';
import Head from 'next/head';
import styled from 'styled-components';

import {Footer} from 'UI/components/layout/raredeals/Login/Footer';
import {LoginAside} from 'UI/components/layout/raredeals/Login/LoginAside';

import type {WithChildren} from 'types/global';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
}

/**
 * The `LoginLayout` component serves as a structural layout for login-related pages, organizing content into a responsive format.
 * This component divides the screen into main content and a sidebar that is hidden on smaller screens (as indicated by the `Hidden` component).
 * The `LoginAside` component is included to provide additional content or instructions relevant to the login process and is only visible on larger screens.
 * Inside the main container, the children are rendered, allowing for dynamic insertion of various login forms or related content.
 * A `Footer` component is also included within the main content area, ensuring consistent placement across login pages.
 *
 * @param props          An object containing the properties for the component. This includes `children`, which are React nodes to be rendered within the layout, and an optional `testId` for specifying a unique identifier for testing purposes.
 * @param props.children The React nodes that should be rendered within the layout, typically containing login forms or related content.
 * @param props.testId   An optional unique identifier for the component, used for testing purposes to locate the component within the DOM.
 * @returns A JSX element representing a structured layout for login-related content, enhancing usability and design coherence across different device sizes.
 *
 * @example
 * ```tsx
 * <LoginLayout testId="user-login">
 *   <LoginForm />
 * </LoginLayout>
 * // This example renders the `LoginForm` within the `LoginLayout`, providing a full-page experience with a footer and optional sidebar on larger screens.
 * ```
 */
const LoginLayout = ({children, testId = 'Login'}: WithChildren<ComponentProps>) => (
    <LoginWrapper data-cy={testId}>
        <Head>
            <title>Rare Deals | corratec</title>
            <meta content="#000" name="theme-color" />
        </Head>
        <LayoutWrap>
            <Hidden sm xs>
                <LoginAside />
            </Hidden>
            <Container as="main">
                <Content>
                    <AnimatePresence>
                        {children}
                        <Footer />
                    </AnimatePresence>
                </Content>
            </Container>
        </LayoutWrap>
    </LoginWrapper>
);

export {LoginLayout};

const LoginWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const LayoutWrap = styled.div`
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
`;

const Content = styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 86.7rem;
    width: 100%;
`;