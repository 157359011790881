import {useEffect, useState} from 'react';

import {useTranslation} from 'next-i18next';

import {useFormFieldValue} from 'UI/hooks/useFormFieldValue';
import {useThemeColors} from 'UI/hooks/useThemeColors';

/**
 * The `useRequestCode` hook is a custom React hook that manages the form state for requesting an access code via email.
 * It primarily handles the validation of the email input field to determine whether the submit button should be enabled or disabled.
 * The hook utilizes the `useFormFieldValue` to listen for changes in the email input and updates the `submitIsDisabled` state based on the presence of input data.
 * This setup ensures that the form's submit action is only available when the user has entered a valid email address, enhancing form usability and preventing errors.
 * Additionally, the hook provides theme-based color settings and localized text resources for rendering within the component using it.
 *
 * @returns An object containing the current color theme settings, the disabled state of the submit button, and localized text strings.
 *
 * @example
 * ```tsx
 * const {colors, submitIsDisabled, t} = useRequestCode();
 * // Use these values to render the form with conditional styles and enabled/disabled states.
 * ```
 */
export const useRequestCode = () => {
    const {t} = useTranslation('raredeals', {keyPrefix: 'login'});
    const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    const colors = useThemeColors();
    const input = useFormFieldValue('email')!;

    useEffect(() => {
        if (input) {
            setSubmitIsDisabled(false);
        } else {
            setSubmitIsDisabled(true);
        }
    }, [input]);

    return {
        colors,
        submitIsDisabled,
        t
    };
};