
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_sites/raredeals/login",
      function () {
        return require("private-next-pages/_sites/raredeals/login.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_sites/raredeals/login"])
      });
    }
  