import {Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';

import {Button} from 'UI/components/action/raredeals/button';
import {InputField} from 'UI/components/action/raredeals/InputField';
import {Color, P} from 'UI/components/layout/raredeals/Text';

import {DropdownTransition} from 'UI/animations/raredeals';
import {useRequestCode} from 'UI/modules/raredeals/login/RequestCode/useRequestCode';

import {ArrowRight} from 'Images/icons/raredeals';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `handleSwitch` function is triggered when the cancel button is clicked, typically used to toggle the visibility of the component or switch between UI states.
     *
     * @param showLogin A boolean value that determines whether the login form should be displayed or not.
     */
    handleSwitch(showLogin: boolean): void;
    /**
     * The `requestCodeResponse` property represents the response from the request code action, determining the UI state to display.
     */
    requestCodeResponse: 'error' | 'success' | null;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
}

/**
 * The `RequestCode` component facilitates user interaction for requesting a special access code via an email form.
 * This component is crucial for scenarios where access to certain parts of the website is restricted and requires authentication through a code sent to a user's email.
 * It includes an input field for the user to enter their email, a submit button to request the code, and a cancel button to revert the action.
 * The component uses a custom hook, `useRequestCode`, to handle form submission and integrates localization support for dynamic text rendering via the `useTranslation` hook.
 *
 * @param props              The component props.
 * @param props.handleSwitch A function triggered when the cancel button is clicked, typically used to toggle the visibility of the component or switch between UI states.
 * @param props.testId       A string that provides a unique identifier for the component which is also used for selecting the component during testing.
 *
 * @returns A React component designed for requesting an access code via email, including input fields and action buttons.
 *
 * @example
 * ```tsx
 * // Example of using the RequestCode component in a parent component's render method.
 * <RequestCode testId="requestCodeComponent" handleSwitch={toggleVisibilityFunction} />
 * ```
 */
const RequestCode = observer(({handleSwitch, requestCodeResponse, testId = 'RequestCode'}: ComponentProps) => {
    const {colors, submitIsDisabled, t} = useRequestCode();

    return (
        <Wrapper data-cy={testId}>
            <InputField initialValue="" label={t('email')} name="email" placeholder={t('email')} type="email" />
            {requestCodeResponse === 'error' && (
                <StyledResponse
                    key="error"
                    animate="animate"
                    exit="exit"
                    initial="exit"
                    variants={DropdownTransition}
                >
                    <P $size="xs">
                        <Color $color={colors.raredealsErrorColor}>
                            {t('requestCodeError')}
                        </Color>
                    </P>
                </StyledResponse>
            )}
            {requestCodeResponse === 'success' && (
                <StyledResponse
                    key="success"
                    animate="animate"
                    exit="exit"
                    initial="exit"
                    variants={DropdownTransition}
                >
                    <Spacer y={2} />
                    <P $size="xs">{t('requestCodeSuccess')}</P>
                </StyledResponse>
            )}
            <Spacer y={10} />
            <Button
                as="button"
                isDisabled={submitIsDisabled}
                type="submit"
                variant="primary"
                width="full"
            >
                <StyledP $size="sm">
                    {t('requestCode')}
                    <Spacer x={1} />
                    <ArrowRight />
                </StyledP>
            </Button>
            <Spacer y={2} />
            <StyledButton
                type="button"
                variant="ghost"
                onClick={() => handleSwitch(true)}
            >
                <StyledP $size="sm" $weight="semibold">{t('cancel')}</StyledP>
            </StyledButton>
        </Wrapper>
    );
});

RequestCode.displayName = 'RequestCode';

export {RequestCode};

const Wrapper = styled.div``;

const StyledButton = styled(Button)`
    margin: 0 auto;
`;

const StyledResponse = styled(motion.div)`
    position: absolute;
`;

const StyledP = styled(P)`
    align-items: center;
    display: flex;
`;