import {Row, Spacer} from '@nfq/react-grid';
import styled from 'styled-components';

import {FooterLinks} from 'UI/components/content/raredeals/FooterLinks';

import {useThemeColors} from 'UI/hooks/useThemeColors';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
}

/**
 * The `Footer` component is a simple React functional component designed to serve as the footer section of a webpage.
 * Currently, it serves as a placeholder indicating where the actual footer content will be implemented. This component is primarily
 * structured using a `Wrapper` element, which can be styled and expanded with actual content such as navigation links, contact information,
 * or additional resources as needed. The use of a `testId` prop ensures that the component can be easily targeted in automated tests.
 *
 * @param props        An object containing optional properties for the component. `testId` is used for identifying the component within test environments.
 * @param props.testId A unique identifier for the component, used for testing purposes to locate the component within the rendered tree.
 * @returns A JSX element that serves as a placeholder for the footer's actual content, indicating the intended area for future development.
 *
 * @example
 * ```tsx
 * <Footer testId="main-footer" />
 * // Renders a footer component with a designated test identifier for testing purposes.
 * ```
 */
const Footer = ({testId = 'Footer'}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <Wrapper data-cy={testId}>
            <Row>
                <Spacer x={{lg: 1, xs: 0}} isNotStretching />
                <FooterLinks textColor={colors.raredealsPrimaryFontColor} />
                <Spacer x={{lg: 10, xs: 0}} isNotStretching />
            </Row>
            <Spacer y={{md: 12, xs: 6}} isNotStretching />
        </Wrapper>
    );
};

Footer.displayName = 'Footer';

export {Footer};

const Wrapper = styled(Row)`
    flex: 0;
    flex-direction: column;
    gap: 0;
    margin-top: auto;
    width: 100%;
`;