import {useMutateRepository} from '@nfq/typed-next-api';

import MerchantService from 'Application/services/MerchantService';

/**
 * Custom hook which encapsulates the API request to request an access token.
 *
 * @returns An object containing the trigger for the request and the error of the request, if there is one.
 */
export const useRequestAccessToken = () => {
    const {error, trigger} = useMutateRepository<typeof MerchantService.requestAccessToken>(
        'AccessToken',
        MerchantService.requestAccessToken,
        {revalidate: false}
    );

    return {
        error,
        requestAccessToken: trigger
    };
};