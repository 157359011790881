import {useEffect, useRef, useState} from 'react';

import {HTTP_METHODS} from '@nfq/typed-next-api';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';


import {useRequestAccessToken} from 'Application/useCases/merchants/useRequestAccessToken';
import {useLogin} from 'Application/useCases/useLogin';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import type {HandleFormSubmit} from 'Shared/formHelperTypes';

/**
 * The `useLoginContent` hook encapsulates logic related to the login and access token request processes for the application.
 * It provides mechanisms to handle form submissions for both logging in with an access code and requesting an access token via email.
 * This hook manages several states: it toggles between showing the login form and the request access token form, handles login errors, and tracks the response status of access token requests.
 * The hook uses several other hooks to manage form values, translations, and navigation, making it a central part of the authentication flow in the application.
 *
 * @returns An object containing properties and methods related to the login and request access token processes including form handlers, state toggles, color themes, and text resources.
 *
 * @example
 * ```tsx
 * const {
 *   colors,
 *   handleSubmit,
 *   handleSubmitLogin,
 *   handleSwitch,
 *   hasLoginError,
 *   isShowingLogin,
 *   requestCodeResponse,
 *   t
 * } = useLoginContent();
 * // These values and functions are used within a component to manage login and token request interactions.
 * ```
 */
export const useLoginContent = () => {
    const {i18n, t} = useTranslation('raredeals', {keyPrefix: 'login'});
    const colors = useThemeColors();
    const router = useRouter();
    const {login} = useLogin();
    const {requestAccessToken} = useRequestAccessToken();
    const [isShowingLogin, setIsShowingLogin] = useState(true);
    const [hasLoginError, setHasLoginError] = useState(false);
    const [requestCodeResponse, setRequestCodeResponse] = useState<'error' | 'success' | null>(null);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const selectedLanguage = i18n.language;

    /**
     * Switches between the login and request code forms.
     *
     * @param showLogin A boolean value indicating whether to show the login form.
     */
    const handleSwitch = (showLogin: boolean) => {
        setIsShowingLogin(showLogin);
    };


    /**
     * The `useEffect` hook is used to manage the timeout for resetting the request code response state.
     * It is triggered when the `requestCodeResponse` state changes, and if the response is not `null`, it sets a timer to reset the state after two seconds.
     * This ensures that the success or error message is displayed for a short duration before being cleared to allow for new interactions.
     * The timer is cleared if the response changes before the timeout completes to prevent the state from being reset prematurely.
     */
    useEffect(() => {
        if (requestCodeResponse) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            const timer = setTimeout(() => {
                resetState();
            }, 2000);

            timeoutRef.current = timer;

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [requestCodeResponse]);

    /**
     * Resets the state of the request code response.
     * This function is called after a successful or failed request code submission to reset the state after a set duration.
     * It sets the `requestCodeResponse` state to `null`, clearing any previous success or error messages.
     */
    const resetState = () => {
        setRequestCodeResponse(null);
    };

    /**
     * Handles the form submission for requesting an access token.
     * This function is triggered when the user submits the form to request an access token using their email address.
     * It makes an API call to request an access token, handling both success and error states by updating the state accordingly.
     * If the email field is empty, the state is set to 'error' to indicate an invalid submission.
     *
     * @param props        The form values.
     * @param props.values The form values.
     */
    const handleSubmit: HandleFormSubmit = async ({values}) => {
        resetState();
        const {email} = values;

        if (!email) {
            setRequestCodeResponse('error');

            return;
        }

        try {
            const res = await requestAccessToken({
                body: {
                    email,
                    selectedLanguage
                },
                method: HTTP_METHODS.POST
            });

            if (res?.success) {
                setRequestCodeResponse('success');
            }
        } catch (e) {
            setRequestCodeResponse('error');
        }
    };

    useEffect(() => {
        if (hasLoginError) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            const timer = setTimeout(() => {
                setHasLoginError(false);
            }, 2000);

            timeoutRef.current = timer;

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [hasLoginError]);

    /**
     * `handleSubmitLogin` is a function designed to handle the form submission for the login process, specifically when logging in with an access code.
     * It captures user input values from the form, particularly the access code, and attempts to authenticate the user by invoking the `login` function.
     * If the login is successful, the function redirects the user to the homepage. If there is a failure during the login attempt, such as incorrect credentials
     * or server issues, it sets the `hasLoginError` state to true, which can be used to trigger error messages or other UI updates to inform the user of the failed login attempt.
     * This function ensures that user interactions are managed correctly, providing a seamless and secure user experience during login.
     *
     * @param props        An object containing form values, including the access code used for the login attempt.
     * @param props.values An object containing form values, including `accessCode` and `email` used for the login attempt.
     * @returns A promise that resolves after the login attempt is completed, handling both the success and error scenarios.
     *
     * @example
     * ```ts
     * // Example usage within a form's onSubmit handler.
     * <form onSubmit={handleSubmitLogin}>
     *   <input name="accessCode" placeholder="Enter access code" />
     *   <input name="email" placeholder="Enter email" />
     *   <button type="submit">Login</button>
     * </form>
     * ```
     */
    const handleSubmitLogin: HandleFormSubmit = async ({values}) => {
        const {accessCode, email} = values;

        try {
            const success = await login({
                body: {
                    accessToken: accessCode,
                    email
                },
                method: HTTP_METHODS.POST
            });

            if (success) {
                await router.push('/');
            }
        } catch (e) {
            setHasLoginError(true);
        }
    };

    return {
        colors,
        handleSubmit,
        handleSubmitLogin,
        handleSwitch,
        hasLoginError,
        isShowingLogin,
        requestCodeResponse,
        t
    };
};