import {Col, media, Row, Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Form} from 'UI/components/action/shared/Form';
import {P} from 'UI/components/layout/raredeals/Text';

import {LoginSwitchTransition} from 'UI/animations/raredeals';
import {RequestCode} from 'UI/modules/raredeals/login/RequestCode';
import {RequestLogin} from 'UI/modules/raredeals/login/RequestLogin';
import {useLoginContent} from 'UI/modules/raredeals/login/useLoginContent';

import {Logo} from 'Images/icons';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
}

/**
 * The `LoginContent` component is a React component that renders the login section with an introductory message and a placeholder for a login form.
 * It utilizes various layout components such as `Wrapper`, `IntroWrapper`, `Spacer`, and `Col` to structure the content. The component is designed
 * to be responsive, adjusting the layout for different screen sizes using properties like `md` and `xs` on grid components. The `StyledLogo` is displayed
 * at the top, followed by a promotional text, and then a placeholder area where a login form will eventually be integrated. This setup ensures that users
 * are greeted with a clear and inviting interface when accessing the login area.
 *
 * @param props        The component props containing an optional `testId` used primarily for targeting the component during testing.
 * @param props.testId A unique identifier for the component, used for testing purposes to locate the component within the rendered tree.
 * @returns A JSX element representing the structured layout for the login content area, ready to integrate with a form and additional functionality.
 *
 * @example
 * ```tsx
 * <LoginContent testId="main-login-content" />
 * // Renders the login content with a designated test identifier.
 * ```
 */
const LoginContent = ({testId = 'LoginContent'}: ComponentProps) => {
    const {
        handleSubmit,
        handleSubmitLogin,
        handleSwitch,
        hasLoginError,
        isShowingLogin,
        requestCodeResponse,
        t
    } = useLoginContent();

    return (
        <Wrapper align="center" data-cy={testId} direction="column">
            <IntroWrapper md={10} xs={12}>
                <StyledLogo />
                <Spacer y={6} isInline isNotStretching />
                <Col>
                    <P $align="center" $size="sm">
                        {t('introText')}
                    </P>
                </Col>
            </IntroWrapper>
            <Spacer y={{lg: 16, xs: 8}} isNotStretching />
            <StyledFormWrapper md={10} xl={8} xs={12}>
                {isShowingLogin ? (
                    <motion.div
                        key="true"
                        animate="animate"
                        exit="exit"
                        initial="exit"
                        variants={LoginSwitchTransition}
                    >
                        <Form id="raredeals-request-login" onSubmit={handleSubmitLogin}>
                            <RequestLogin handleSwitch={handleSwitch} hasLoginError={hasLoginError} />
                        </Form>
                    </motion.div>
                ) : (
                    <motion.div
                        key="false"
                        animate="animate"
                        exit="exit"
                        initial="initial"
                        variants={LoginSwitchTransition}
                    >
                        <Form id="raredeals-request-code" onSubmit={handleSubmit}>
                            <RequestCode handleSwitch={handleSwitch} requestCodeResponse={requestCodeResponse} />
                        </Form>
                    </motion.div>
                )}
            </StyledFormWrapper>
        </Wrapper>
    );
};

LoginContent.displayName = 'LoginContent';

export {LoginContent};

const Wrapper = styled(Row)`
    flex: 0;
    margin-top: auto;
    width: 100%;
`;

const StyledLogo = styled(Logo)`
    max-width: 25.3rem;

    ${media('md')} {
        max-width: 31.3rem;
    }
`;

const IntroWrapper = styled(Col)`
    align-items: center;
    display: flex;
    flex: 0;
    flex-direction: column;
`;

const StyledFormWrapper = styled(Col)`
    position: relative;
    width: 100%;
`;