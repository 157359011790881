import {useFormContext} from 'UI/hooks/useFormContext';

import type {FormValue} from 'Shared/formHelperTypes';


/**
 * Form Field Value Hook.
 *
 * This hook retrieves the current value of a specific form field from the form context.
 *
 * @param  name The name of the form field.
 *
 * @returns The current value of the specified form field.
 *
 * @throws {Error} If `useFormFieldValue` is used outside a `FormProvider` component.
 *
 * @example
 * ```tsx
 * const MyFormComponent = () => {
 *   const username = useFormFieldValue('username');
 *
 *   return (
 *     <div>
 *       <p>Username: {username}</p>
 *     </div>
 *   );
 * };
 * ```
 */
export const useFormFieldValue = <T extends FormValue>(name: string) => {
    const formContext = useFormContext();

    if (!formContext) {
        throw new Error('useFormFieldValue must be used within a FormProvider');
    }

    const {form} = formContext;

    return form.values[String(name)] as T | undefined;
};