/* eslint-disable react/jsx-props-no-spreading */
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import {LoginLayout} from 'UI/layouts/raredeals/LoginLayout';
import {LoginContent} from 'UI/modules/raredeals/login/LoginContent';

import type {GetServerSideProps} from 'next';
import type {NextPageWithLayout} from 'types/global';

/**
 * The `Login` component is a Next.js page component.
 * It is used as a route and can be accessed throught the nextjs router.
 *
 * @returns A ReactNode representing the `Login` page.
 */
const Login: NextPageWithLayout = () => (
    <LoginContent />
);

/**
 * `getLayout` is a static property of the `Login` component that is a function designed to wrap the component with a specified layout.
 * It receives the router object, page properties, and the PageComponent and returns the PageComponent wrapped with the necessary layout and properties.
 * This property is essential for applying consistent layouts across different pages in the application.
 *
 * @param router        The Next.js router object representing the current route of the application.
 * @param pageProps     The page properties object representing the properties of the current page.
 * @param PageComponent The PageComponent representing the current page.
 * @returns A ReactNode representing the PageComponent wrapped with the necessary layout and properties.
 *
 * @example
 * ```tsx
 * const LayoutWrappedComponent = Login.getLayout(router, pageProps, PageComponent);
 * ```
 */
Login.getLayout = (router, pageProps, PageComponent) => (
    <LoginLayout>
        <PageComponent router={router} {...pageProps} />
    </LoginLayout>
);

/**
 * `getLayoutKey` is a static property of the `Login` component that is a function designed to return the layout key string.
 * It is used to determine if the layout changed between pages to apply transitions between different layouts.
 * This property is crucial for managing different layouts within the application and for providing a specific layout key for the `Login` component.
 *
 * @returns A string representing the layout key.
 *
 * @example
 * ```tsx
 * const layoutKey = Login.getLayoutKey();
 * ```
 */
Login.getLayoutKey = () => 'Page';

export default Login;

/**
 * `getServerSideProps` is a Next.js function designed to fetch data on each request and pass it as props to the page.
 * It is used in the context of Server-side Rendering and is crucial for pages that need to perform data fetching on every request, allowing for real-time data fetching and SEO optimization.
 * This function is essential for fetching the necessary data required by a page and providing it as props, ensuring the correct and up-to-date data is available during the rendering process.
 *
 * @param context        The context object contains parameters of the server-side page, including `params` which holds the dynamic segments of the route.
 * @param context.locale The locale of the page.
 * @param context.req    The HTTP request object.
 * @param context.res    The HTTP response object.
 * @returns A promise resolving to an object containing the `props` to be passed to the page component.
 */
export const getServerSideProps: GetServerSideProps = async ({locale}) => {
    const translations = await serverSideTranslations(locale ?? 'de', ['common', 'raredeals']);

    return {props: {...translations}};
};