import {api} from '@nfq/typed-next-api';

import type {HTTP_METHODS, MutationRepoArgs} from '@nfq/typed-next-api';
import type {requestAccessToken} from 'ApiRoutes/accesscode';
import type {MerchantAdapter} from 'Domain/adapters/MerchantAdapter';
import type {AccessCodeRequest} from 'Domain/entities/Merchant';

/**
 * Merchant service class.
 *
 * This class implements the MerchantApdapter interface and provides a method to request an access token.
 *
 * @implements {MerchantAdapter}
 */
class MerchantService implements MerchantAdapter {
    /**
     * Method to request an access token.
     *
     * This method sends a request to the backend to create a new access token for the merchant identified by the email.
     */
    async requestAccessToken(
        key: string,
        {arg: {body, method}}: MutationRepoArgs<AccessCodeRequest, HTTP_METHODS.POST>
    ) {
        return api<typeof requestAccessToken>('/api/accesscode', {
            body,
            method
        });
    }
}

export default new MerchantService();